.ContactContant {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #f4f5fa;
}

.ProfileBox {
  box-shadow: 0px 0px 8px 0px #0000000f;
  /* width: 321px; */
  width: 410px;
  padding: 10px 5px 10px 0px;
  height: 100%;
  background: #ffffff;
  border-top: 1px solid #dbdbdb;
}

/* ----------contact list css------------ */
.ContactList {
  background-color: #f9f9f9;
  padding: 25px;
  display: inline-block;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

.ContactList p {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
}

.Contactlist p {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  font-size: 20px;
  font-weight: 600;
  height: 100%;
}

.custom {
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* ---------profile css-------------- */
.userProfile {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  height: 100%;
}

.userProfile .uploadBox {
  width: 70px;
  height: 69px;
  margin: 0px 10px 0px 15px;
}

.userProfile h1 {
  font-size: 20px;
}

.userProfile p {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #757c80;
  margin-top: 3px;
  margin: 0;
  padding-right: 6px;
  width: max-content;
}

.userProfile span {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  margin-top: 3px;
  margin: 0;
  padding-right: 12px;
}

.imageBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150px;
  width: 190px;
  text-align: center;
}

.uploadLabel {
  cursor: pointer;
  align-items: center;
  margin-top: 10px;
  color: #333333;
  font-size: 11px;
  font-weight: 600;
}

.uploadIcon {
  border: 2px solid #dedede;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50px;
  background-color: #f9f9f9;
}

.uploadBox p {
  font-size: 10px;
  font-weight: 600;
  width: 100%;
}

.uploadIcon svg {
  color: #9e9e9e;
  font-size: 40px;
  font-weight: 600;
  display: flex;
  margin: 10px auto;
}

.uploadImg {
  width: 100%;
  max-width: 267px;
  margin: 20px auto;
  border-radius: 5px;
  height: 100%;
  max-height: 276px;
  object-fit: contain;
}

.imgUpload {
  position: relative;
}

.imgUpload .afterUploadImg {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border: 1px solid #dedede;
  padding: 1px 1px;
  border-radius: 50px;
}

.closeIcon {
  font-size: 18px;
  position: absolute;
  left: 60px;
  top: 0px;
  color: #fff;
  background-color: #406394;
  font-weight: 600;
  border-radius: 50%;
}

.inputGroup {
  height: 45px;
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.formControl {
  width: calc(100% - 8px);
  height: 31px;
  color: #17191a;
  transition: border 0.3s ease-out;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.45px;
  line-height: 21px;
  border: none;
}

.formControl::placeholder {
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.45px;
  line-height: 21px;
  color: #8f8f8f;
}

.formControl:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #dddddd;
  padding-left: 6px;
  border-radius: 4px;
}

.formControlPhone {
  width: calc(100% - 8px);
  height: 31px;
  color: #17191a;
  transition: border 0.3s ease-out;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.15px;
  line-height: 17px;
  border: none;
}

.formControlPhone::placeholder {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.15px;
  line-height: 17px;
  color: #8f8f8f;
}

.formControlPhone:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #dddddd;
  padding-left: 6px;
  border-radius: 4px;
}

.formControlEmail {
  width: calc(100% - 8px);
  height: 31px;
  color: #17191a;
  transition: border 0.3s ease-out;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  border: none;
}

.formControlEmail::placeholder {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  color: #8f8f8f;
}

.formControlEmail:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #dddddd;
  padding-left: 6px;
  border-radius: 4px;
}

.errorBorder:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #d0021b;
  padding-left: 6px;
  border-radius: 4px;
}

.NameClass {
  width: 65%;
}

.Messageicon {
  cursor: pointer;
  margin: 0.5rem;
  position: absolute;
  right: 0;
  top: -2px;
}

.CancelBtn {
  border: none;
  background-color: #f0f0f0;
  color: #545454;
  padding: 6px 20px;
  border-radius: 4px;
  font-size: 17px;
  font-weight: 600;
}

.DisabledBtn {
  border: none;
  background-color: #f0f0f0;
  color: #d8d8d8;
  padding: 6px 20px;
  border-radius: 4px;
  margin-left: 10px;
  font-size: 17px;
  font-weight: 600;
}

.FileUpload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.FileUpload .uploadIcon {
  border: 2px solid #dedede;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 70px;
  background-color: #f9f9f9;
}

.FileUpload .uploadIcon svg {
  color: #9e9e9e;
  font-size: 60px;
  font-weight: 600;
  display: flex;
  margin: 10px auto;
}

.FileUpload .imgUpload .afterUploadImg {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border: 1px solid #dedede;
  padding: 1px 1px;
  border-radius: 70px;
}

@media only screen and (max-width: 1100px) {
  .userProfile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    gap: 30px;
  }
}

@media only screen and (max-width: 840px) {
  .NameClass {
    width: 55%;
  }
}

@media only screen and (max-width: 700px) {
  .ProfileBox {
    position: absolute;
    right: 0;
    width: 250px;
  }
}

@media only screen and (max-width: 500px) {
  .ContactList {
    width: 100%;
    padding: 25px 10px;
  }
  .NameClass {
    width: 45%;
  }
}

.Contactlist {
  width: 100%;
  overflow: auto;
  scrollbar-width: thin;
  border-radius: 3px;
}

.Contactlist table {
  width: 100%;
  border-collapse: collapse;
}

.Contactlist table thead {
  position: sticky;
  top: -2px;
  border-bottom: 1px solid #e6e6e6;
}

.ContactModal .PhoneInput input.form-control {
  padding: 0px 45px;
  margin-bottom: 20px;
}
.PhoneInput {
  margin-bottom: 20px;
}

.PhoneInput .react-tel-input .flag-dropdown {
  border-radius: 0% !important;
}

.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px !important;
  font-weight: 500;
}
